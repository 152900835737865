import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row ma-n2"
}
const _hoisted_2 = {
  key: 0,
  class: "col-12 pa-2"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_ListMap = _resolveComponent("ListMap")!
  const _component_GridMap = _resolveComponent("GridMap")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_spinner, {
      loading: _ctx.loading,
      wide: ""
    }, null, 8, ["loading"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.maps.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.mapView === "list")
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ListMap, {
                        maps: _ctx.maps,
                        onNextPage: _ctx.nextPage,
                        onOpenDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("open-details", $event.id_res_name1, "resource")))
                      }, null, 8, ["maps", "onNextPage"])
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.maps, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["pa-2", _ctx.isTablet ? (!(_ctx.store.state.globals.detailsTypeResource || _ctx.store.state.globals.detailsResource) && _ctx.isLaptop ? "col-2" : "col-3") : "col-6"]),
                        key: i
                      }, [
                        _createVNode(_component_GridMap, {
                          item: item,
                          onNextPage: _ctx.nextPage,
                          onOpenDetails: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("open-details", $event.id_res_name1, "resource")))
                        }, null, 8, ["item", "onNextPage"])
                      ], 2))
                    }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t("form.notify.noResultsFound")), 1)
              ]))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}