
import {defineComponent, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {Maps} from "@/models";

export default defineComponent({
	emits: ['next-page', 'open-details'],
	props: {
		item: {
			type: Object as PropType<Maps.Map>,
			required: true
		}
	},
	setup() {
		const { t } = useI18n();
		return { t }
	}
})
