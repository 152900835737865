
import {defineComponent, ref, onMounted, computed, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRoute, useRouter} from "vue-router";
import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'
import ListMap from "@/views/maps/components/maps/components/ListMap.vue";
import GridMap from "@/views/maps/components/maps/components/GridMap.vue";

export default defineComponent({
	emits: ["item-refresh-request", "open-details"],
	props: {
		detailsId: Number,
		isRefreshRequest: {
			type: Boolean,
			default: false
		},
		selectedTypeLink: Array
	},
	components: {
		ListMap,
		GridMap
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const mapView = computed(() => store.state.globals.mapView);

		const nextPage = (id_res_name1: number) => router.push({name: 'linkedResources', params: {idRes: id_res_name1}});

		const maps = computed(() => {
			let groups = groupBy(store.state.dictionaries.maps.filter(map => map.id_res_name2), 'id_res_name1');

			return uniqBy(store.state.dictionaries.maps.map(map => {
				return {
					...map,
					res: (groups[map.id_res_name1] ?? []).map(group => ({
						avatar: group.avatar_2,
						name: group.name2
					}))
				}
			}), "id_res_name1")
		});

		const loading = ref(true);
		const load = async () => {
			if(store.state.globals.enterpriseId) {
				loading.value = true;
				await store.dispatch('dictionaries/getMaps', route.params.idTypeRes);
				loading.value = false;
				emit('item-refresh-request');

				if(store.state.globals.detailsResource) {
					await store.dispatch('dictionaries/getResources');
					emit("open-details", props.detailsId, 'resource')
				}

				if(store.state.globals.detailsTypeResource) {
					await store.dispatch('dictionaries/getTypeResources');
					emit("open-details", props.detailsId, 'typeResource')
				}
			} else {
				router.push({name: "change_enterprise"})
			}
		}

		onMounted(async () => {
			await store.dispatch('dictionaries/getTypeResources');
			await store.dispatch('dictionaries/getResources');
			await load();
		});
		watch(() => props.isRefreshRequest, async () => {
			if(props.isRefreshRequest)
				await load()
		});

		const isTablet = ref(true);
		isTablet.value = window.innerWidth >= 600;
		window.addEventListener('resize', () => isTablet.value = window.innerWidth >= 600);

		const isLaptop = ref(true);
		isLaptop.value = window.innerWidth >= 1264;
		window.addEventListener('resize', () => isLaptop.value = window.innerWidth >= 1264);

		return {
			t, store,
			mapView, nextPage,
			loading, load,
			maps,
			isTablet, isLaptop
		}
	}
})
