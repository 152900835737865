import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/no-image.png'


const _hoisted_1 = { class: "maps-list-item pa-2" }
const _hoisted_2 = { class: "maps-list-item-img mb-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  src: _imports_0
}
const _hoisted_5 = { class: "maps-list-item-text text-body-2 text-center" }
const _hoisted_6 = {
  key: 0,
  class: "maps-list-item-res my-2 mx-1"
}
const _hoisted_7 = { class: "row ma-n1" }
const _hoisted_8 = { class: "maps-list-item-res-item" }
const _hoisted_9 = { class: "maps-list-item-img small" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  src: _imports_0
}
const _hoisted_12 = { class: "maps-list-item-link mb-0 ml-1 text-left" }
const _hoisted_13 = {
  key: 0,
  class: "maps-list-item-status bg-green"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next-page', _ctx.item.id_res_name1)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        ((_ctx.item.avatar_1 ?? "").indexOf("base64") > 0)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.item.avatar_1
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.item.name1), 1),
      (_ctx.item.res.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.res, (res, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-auto pa-1",
                  key: i
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, [
                      ((res.avatar ? res.avatar : "").indexOf("base64") > 0)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: res.avatar
                          }, null, 8, _hoisted_10))
                        : (_openBlock(), _createElementBlock("img", _hoisted_11))
                    ]),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(res.name), 1)
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_app_button, {
        class: "mt-2",
        block: "",
        small: "",
        outlined: "",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit("open-details", _ctx.item)), ["stop"])),
        style: {"text-transform":"capitalize"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("button.details")), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.item.res.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_13))
      : _createCommentVNode("", true)
  ]))
}